import Component from './Component'
import globals from './Globals'

export default class Camera extends Component {
  constructor() {
    super()

    this.camera = new THREE.PerspectiveCamera(75, globals.sizes.width / globals.sizes.height, 0.1, 100)
    this.camera.position.x = 4
    this.camera.position.y = 4
    this.camera.position.z = 4

    // Event Listening
    window.addEventListener('resize', () => {
      // Update sizes
      globals.sizes.width = window.innerWidth
      globals.sizes.height = window.innerHeight

      this.camera.aspect = globals.sizes.width / globals.sizes.height
      this.camera.updateProjectionMatrix()
    })
  }
}