import Camera from './Camera'
import globals from './Globals'

export default class World {
  constructor() {
    this.canvas = document.querySelector('canvas.game-webgl')
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas
    })
    this.camera = new Camera()
    this.scene = new THREE.Scene()

    // Event Listening
    window.addEventListener('resize', () => {
      // Update sizes
      globals.sizes.width = window.innerWidth
      globals.sizes.height = window.innerHeight

      // Update renderer
      this.renderer.setSize(globals.sizes.width, globals.sizes.height)
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })
  }

  init() {
    // Renderer
    this.renderer.setSize(globals.sizes.width, globals.sizes.height)
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Scene
    this.scene.background = new THREE.Color('white')
    this.scene.add(this.camera.camera)

    // Lighting
    const light = new THREE.AmbientLight(0xffffff, 1)
    this.scene.add(light)

    // Floor
    const floor = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(50, 50),
      new THREE.MeshStandardMaterial({ color: 0x55ee55 })
    )
    floor.rotation.x = - Math.PI * 0.5
    this.scene.add(floor)
    this.camera.camera.lookAt(floor.position)
  }

  update() {
    this.renderer.render(this.scene, this.camera.camera)
  }
}