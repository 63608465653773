// Deps
import Component from './Component'
import globals from './Globals'
import { SkeletonUtils } from 'three/examples/jsm/utils/SkeletonUtils.js'

export default class SkinInstance extends Component {
  constructor(gameObject, model) {
    super(gameObject)
    this.model = model
    this.model.fbx.scale.set(0.002, 0.002, 0.002)
    this.animRoot = SkeletonUtils.clone(this.model.fbx)
    this.mixer = new THREE.AnimationMixer(this.animRoot)
    gameObject.transform.add(this.animRoot)
    this.actions = []
    this.currentAnimation = null
    this.previousAction = null
    this.isAnimatingOnce = false
  }

  setAnimation(animName) {
    if (this.isAnimatingOnce) {
      return
    }
    if (this.currentAnimation == animName) {
      return
    }
    this.currentAnimation = animName

    const clip = this.model.animations[animName]

    if (this.previousAction) {
      this.previousAction.fadeOut(0.2)
    }

    const action = this.mixer.clipAction(clip)
    action.reset()
    action.fadeIn(0.2)
    action.play()
    this.actions[animName] = action
    this.previousAction = action
  }

  setAnimationOnce(animName) {
    // Blend previous to this one
    this.currentAnimation = animName
    this.previousAction.fadeOut(0.2)

    // Stop animating other stuff
    this.isAnimatingOnce = true

    const clip = this.model.animations[animName]
    const duration = clip.duration
    const action = this.mixer.clipAction(clip)
    action.reset()
    action.fadeIn(0.2)
    action.setLoop(THREE.LoopOnce)
    action.play()

    // Go back to animating
    setTimeout(() => {
      this.isAnimatingOnce = false
    }, duration)
  }

  update() {
    this.mixer.update(globals.deltaTime)
  }
}