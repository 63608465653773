import '../css/style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import testVertexShader from '../shaders/test/vertex.vert'
import testFragmentShader from '../shaders/test/fragment.glsl'
import vert1Shader from '../shaders/vert1.vert'
import frag1Shader from '../shaders/frag1.frag'
import vert2Shader from '../shaders/vert2.vert'
import frag2Shader from '../shaders/frag2.frag'
import vert3Shader from '../shaders/vert3.vert'
import frag3Shader from '../shaders/frag3.frag'
import vert4Shader from '../shaders/vert4.vert'
import frag4Shader from '../shaders/frag4.frag'
import vert5Shader from '../shaders/vert5.vert'
import frag5Shader from '../shaders/frag5.frag'
import vert6Shader from '../shaders/vert6.vert'
import frag6Shader from '../shaders/frag6.frag'
import vert7Shader from '../shaders/vert7.vert'
import frag7Shader from '../shaders/frag7.frag'
import vert8Shader from '../shaders/vert8.vert'
import frag8Shader from '../shaders/frag8.frag'
import vert9Shader from '../shaders/vert9.vert'
import frag9Shader from '../shaders/frag9.frag'
import vert10Shader from '../shaders/vert10.vert'
import frag10Shader from '../shaders/frag10.frag'
import vert11Shader from '../shaders/vert11.vert'
import frag11Shader from '../shaders/frag11.frag'
import vert12Shader from '../shaders/vert12.vert'
import frag12Shader from '../shaders/frag12.frag'
import vert13Shader from '../shaders/vert13.vert'
import frag13Shader from '../shaders/frag13.frag'
import vert14Shader from '../shaders/vert14.vert'
import frag14Shader from '../shaders/frag14.frag'
import vert15Shader from '../shaders/vert15.vert'
import frag15Shader from '../shaders/frag15.frag'
import vert16Shader from '../shaders/vert16.vert'
import frag16Shader from '../shaders/frag16.frag'
import vert17Shader from '../shaders/vert17.vert'
import frag17Shader from '../shaders/frag17.frag'

/**
 * Base
 */
const canvas = document.querySelector('canvas.shaders-webgl')

const init = () => {

  // Debug
  const gui = new dat.GUI()
  const debugObject = {}

  // Scene
  const scene = new THREE.Scene()

  /**
   * Textures
   */
  const textureLoader = new THREE.TextureLoader()

  /**
   * Test mesh
   */
  // Geometry
  let currentShader = 1
  const fragShaders = [
    frag17Shader,
    frag16Shader,
    frag15Shader,
    frag14Shader,
    frag13Shader,
    frag12Shader,
    frag11Shader,
    frag10Shader,
    frag9Shader,
    frag8Shader,
    frag7Shader,
    testFragmentShader,
    frag1Shader,
    frag2Shader,
    frag3Shader,
    frag4Shader,
    frag5Shader,
    frag6Shader,
  ]
  const vertShaders = [
    vert17Shader,
    vert16Shader,
    vert15Shader,
    vert14Shader,
    vert13Shader,
    vert12Shader,
    vert11Shader,
    vert10Shader,
    vert9Shader,
    vert8Shader,
    vert7Shader,
    testVertexShader,
    vert1Shader,
    vert2Shader,
    vert3Shader,
    vert4Shader,
    vert5Shader,
    vert6Shader,
  ]
  const parameters = {
    frag: fragShaders[0],
    vert: vertShaders[0]
  }
  
  let mesh = null
  let material = null
  let geometry = null
  let count = null
  let randoms = null

  const buildShader = () => {
    if (mesh != null) {
      geometry.dispose()
      material.dispose()
      scene.remove(mesh)
    }

    geometry = new THREE.PlaneGeometry(1, 1, 32, 32)
    count = geometry.attributes.position.count
    randoms = new Float32Array(count)

    for (let i = 0; i < count; i++) {
      randoms[i] = Math.random()
    }

    geometry.setAttribute('aRandom', new THREE.BufferAttribute(randoms, 1))

    // Material
    material = new THREE.ShaderMaterial({
      vertexShader: parameters.vert,
      fragmentShader: parameters.frag,
      uniforms: {
        uFrequency: { value: new THREE.Vector2(10, 5) },
        uTime: { value: 0 },
        uColor: { value: new THREE.Color('orange') }
      },
      needsUpdate: true
    })

    // Mesh
    mesh = new THREE.Mesh(geometry, material)
    scene.add(mesh)
  }

  buildShader()

  // DEBUG SHADERS
  debugObject.swapShader = () => {
    if (currentShader == fragShaders.length - 1) {
      currentShader = 0
    } else {
      currentShader++
    }
    parameters.frag = fragShaders[currentShader]
    parameters.vert = vertShaders[currentShader]
  }

  gui.add(debugObject, 'swapShader').onFinishChange(buildShader)

  /**
   * Sizes
   */
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
  }

  window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  })

  /**
   * Camera
   */
  // Base camera
  const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
  camera.position.set(0.25, - 0.25, 1)
  scene.add(camera)

  // Controls
  const controls = new OrbitControls(camera, canvas)
  controls.enableDamping = true

  /**
   * Renderer
   */
  const renderer = new THREE.WebGLRenderer({
    canvas: canvas
  })
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

  /**
   * Animate
   */
  const clock = new THREE.Clock()

  const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // Update material
    if (material != null) {
      material.uniforms.uTime.value = elapsedTime
    }

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
  }

  tick()
}

if (canvas) {
  init()
}