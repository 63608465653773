const healthHeart = document.querySelector('.health-heart')
const inventory = document.querySelector('.inventory')
const inventoryButton = document.querySelector('.inventory-button')
const closeInventoryButton = document.querySelector('.close-inventory')
const heartContainer = document.querySelector('.heart-container')

export default class UI {
  constructor() {
    // Listeners
    inventoryButton.addEventListener('click', this.toggleInventory)
    closeInventoryButton.addEventListener('click', this.toggleInventory)
  }

  toggleInventory() {
    console.log(inventoryButton)
    if (inventory.classList.contains('show')) {
      inventory.classList.remove('show')
    } else {
      inventory.classList.add('show')
    }
  }

  setHealth(current) {
    while (heartContainer.firstChild) {
      heartContainer.removeChild(heartContainer.firstChild)
    }

    for (let i = 0; i < current; i++) {
      let copy = healthHeart.cloneNode(true)
      heartContainer.appendChild(copy)
    }
  }
}