export default class InputManager {
  constructor() {
    this.keys = {}
    const keyMap = new Map()

    const setKey = (keyName, pressed) => {
      const keyState = this.keys[keyName]
      keyState.justPressed = pressed && !keyState.down
      keyState.down = pressed
    }

    const addKey = (keyCode, name) => {
      this.keys[name] = { down: false, justPressed: false }
      keyMap.set(keyCode, name)
    }

    const setKeyFromKeyCode = (keyCode, pressed) => {
      const keyName = keyMap.get(keyCode)
      if (!keyName) {
        return;
      }
      setKey(keyName, pressed)
    }

    addKey("ArrowLeft", 'left')
    addKey("ArrowRight", 'right')
    addKey("ArrowUp", 'up')
    addKey("ArrowDown", 'down')
    addKey("KeyA", 'left')
    addKey("KeyD", 'right')
    addKey("KeyW", 'up')
    addKey("KeyS", 'down')
    addKey("Space", 'jump')

    window.addEventListener('keydown', (e) => {
      setKeyFromKeyCode(e.code, true)
    })

    window.addEventListener('keyup', (e) => {
      setKeyFromKeyCode(e.code, false)
    })
  }

  update() {
    for (const keyState of Object.values(this.keys)) {
      if (keyState.justPressed) {
        keyState.justPressed = false
      }
    }
  }
}