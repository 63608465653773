import Component from "./Component";
import SkinInstance from './SkinInstance'
import FiniteStateMachine from './FiniteStateMachine'
import { isClose, aimTowardAndGetDistance } from './Utils'
import globals from './Globals'

export default class Enemy extends Component {
  constructor(gameObject, model) {
    super(gameObject)
    this.moveSpeed = 2

    const skinInstance = gameObject.addComponent(SkinInstance, model)
    const hitRadius = model.size / 2
    const transform = gameObject.transform
    const playerTransform = globals.player.transform
    const maxTurnSpeed = Math.PI * (this.moveSpeed / 4)
    const targetHistory = []

    let targetIndex = 0

    skinInstance.mixer.timeScale = this.moveSpeed
    skinInstance.setAnimation('Armature|Idle')

    function addHistory() {
      const targetGO = globals.history[targetIndex]
      const newTargetPos = new THREE.Vector3()
      newTargetPos.copy(targetGO.transform.position)
      targetHistory.push(newTargetPos)
    }

    this.fsm = new FiniteStateMachine({
      idle: {
        enter: () => {
          skinInstance.setAnimation('Armature|Idle')
        },
        update: () => {
          if (isClose(transform, hitRadius, playerTransform, globals.playerRadius)) {
            this.fsm.transition('waitForEnd')
          }
        },
      },
      waitForEnd: {
        enter: () => {
          skinInstance.setAnimation('Armature|Jump')
        },
        update: () => {
          const lastGO = globals.history[globals.history.length - 1]
          const deltaTurnSpeed = maxTurnSpeed * globals.deltaTime
          const targetPos = lastGO.transform.position
          aimTowardAndGetDistance(transform, targetPos, deltaTurnSpeed)

          if (isClose(transform, hitRadius, lastGO.transform, globals.playerRadius)) {
            this.fsm.transition('goToLast')
          }
        },
      },
      goToLast: {
        enter: () => {
          targetIndex = globals.congaLine.length - 1
          globals.history.push(gameObject)
          skinInstance.setAnimation('Armature|Walk')
        },
        update: () => {
          addHistory()
          const targetPos = targetHistory[0]
          const maxVelocity = globals.moveSpeed * globals.deltaTime
          const deltaTurnSpeed = maxTurnSpeed * globals.deltaTime
          const distance = aimTowardAndGetDistance(transform, targetPos, deltaTurnSpeed)
          const velocity = distance;
          transform.translateOnAxis(kForward, Math.min(velocity, maxVelocity))

          if (distance <= maxVelocity) {
            this.fsm.transition('follow')
          }
        },
      },
      follow: {
        update: () => {
          addHistory()
          const targetPos = targetHistory.shift()
          transform.position.copy(targetPos)
          const deltaTurnSpeed = maxTurnSpeed * globals.deltaTime
          aimTowardAndGetDistance(transform, targetHistory[0], deltaTurnSpeed)
        },
      },
    }, 'idle')
  }
}