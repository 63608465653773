import '../css/style.css'
import TWEEN from '@tweenjs/tween.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.raycast-webgl')

const init = () => {

  // Debug
  const gui = new dat.GUI()

  // Scene
  const scene = new THREE.Scene()

  /**
   * Textures
   */
  const textureLoader = new THREE.TextureLoader()

  const parameters = {}

  /**
   * Environemnt
   */
  const object1 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.5, 16, 16),
    new THREE.MeshBasicMaterial({ color: 0xff0000 })
  )
  object1.position.x = -2

  const object2 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.5, 16, 16),
    new THREE.MeshBasicMaterial({ color: 0xff0000 })
  )

  const object3 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.5, 16, 16),
    new THREE.MeshBasicMaterial({ color: 0xff0000 })
  )
  object3.position.x = 2

  scene.add(object1, object2, object3)

  const square2 = new THREE.Mesh(
    new THREE.BoxBufferGeometry(1.3, 0.7, 0.02),
    new THREE.MeshNormalMaterial({
      transparent: true,
      opacity: 0
    })
  )
  square2.position.y = 0.7

  let square2Tween = new TWEEN.Tween(square2.material).to({ 
    opacity: 1
  }, 500).onStart(() => {
    new TWEEN.Tween(square2.position).to({
      y: 1
    }, 500).easing(TWEEN.Easing.Back.InOut).start()
  }).easing(TWEEN.Easing.Quartic.InOut)

  let square2TweenBack = new TWEEN.Tween(square2.material).to({ 
    opacity: 0 
  }, 500).onStart(() => {
    new TWEEN.Tween(square2.position).to({
      y: 0.7
    }, 500).easing(TWEEN.Easing.Back.InOut).start()
  }).easing(TWEEN.Easing.Quartic.InOut)

  scene.add(square2)

  /**
   * Raycaster
   */
  const raycaster = new THREE.Raycaster()

  /**
   * Sizes
   */
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
  }

  window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  })

  /**
   * Mouse Handling
   */
  const mouse = new THREE.Vector2()

  window.addEventListener('mousemove', (_event) => {
    mouse.x = _event.clientX / sizes.width * 2 - 1
    mouse.y = -(_event.clientY / sizes.height) * 2 + 1
  })

  window.addEventListener('click', (_event) => {
    if (currentIntersect) {
      switch (currentIntersect.object) {
        case object1:
          break
        case object2:
          square2Tween.start()
          setTimeout (() => {
            square2TweenBack.start()
          }, 2000)
          break
        case object3:
          break
      }
    }
  })

  /**
   * Camera
   */
  // Base camera
  const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
  camera.position.z = 4
  scene.add(camera)

  // Controls
  const controls = new OrbitControls(camera, canvas)
  controls.enablePan = false
  controls.enableZoom = false
  controls.enableDamping = true

  /**
   * Renderer
   */
  const renderer = new THREE.WebGLRenderer({
    canvas: canvas
  })
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

  /**
   * Animate
   */
  const clock = new THREE.Clock()
  const objectsToTest = [object1, object2, object3]
  const cameraPosition = camera.getWorldPosition()
  let currentIntersect = null


  const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // Cast Ray
    raycaster.setFromCamera(mouse, camera)

    const intersects = raycaster.intersectObjects(objectsToTest)

    for (const object of objectsToTest) {
      object.material.color.set(0xff0000)
    }

    for (const intersect of intersects) {
      intersect.object.material.color.set(0x0000ff)
    }

    if (intersects.length) {
      if (currentIntersect == null) {
      }
      currentIntersect = intersects[0]
    } else {
      if (currentIntersect) {
      }
      currentIntersect = null
    }

    // Update controls
    controls.update()
    TWEEN.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
  }

  tick()
}

if (canvas) {
  init()
}