const delta = new THREE.Vector3()

export function isClose(obj1, obj1Radius, obj2, obj2Radius) {
  const minDist = obj1Radius + obj2Radius
  const dist = obj1.position.distance(obj2.position)
  return dist < minDist;
}

export function minMagnitude(v, min) {
  return Math.abs(v) > min ? min * Math.sign(v) : v
}

export function aimTowardAndGetDistance(source, targetPos, maxTurn) {
  delta.subVectors(targetPos, source.position)
  const targetRot = Math.atan2(delta.x, delta.z) + Math.PI * 1.5
  const delatRot = (targetRot - source.rotation.y + Math.PI * 1.5) % (Math.PI * 2) - Math.PI
  const deltaRotation = minMagnitude(deltaRot, maxTurn)
  source.rotation.y = THREE.MathUtils.euclideanModulo(source.rotation.y + deltaRotation, Math.PI * 2)
  return delta.length()
}