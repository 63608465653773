// Deps
import Component from './Component'
import SkinInstance from './SkinInstance'
import globals from './Globals'
import InputManager from './InputManager'
import Debug from './Debug'

const kForward = new THREE.Vector3(0, 0, 1)
const kBackward = new THREE.Vector3(0, 0, -0.3)

export default class Player extends Component {
  constructor(gameObject, model) {
    super(gameObject)
    this.skinInstance = gameObject.addComponent(SkinInstance, model)
    this.skinInstance.setAnimation("HumanArmature|Idle")
    this.moveSpeed = 4
    this.turnSpeed = this.moveSpeed / 1.2
    this.inputManager = new InputManager()
    this.maxHealth = 5
    this.currentHealth = 5
    
    const gui = new Debug()
    gui.add(this, 'moveSpeed', 1, 20, 0.1)
  }

  takeDamage(damage) {
    this.currentHealth = this.currentHealt - damage
    if (this.currentHealth <= 0) {
      dead()
    }
  }

  dead() {
    console.log('You died idiot')
  }

  update() {
    const { deltaTime } = globals
    const { transform } = this.gameObject
    const delta = (this.inputManager.keys.left.down ? 1 : 0) +
                  (this.inputManager.keys.right.down ? -1 : 0)
    transform.rotation.y += this.turnSpeed * delta * deltaTime

    if (this.inputManager.keys.up.down) {
      transform.translateOnAxis(kForward, this.moveSpeed * deltaTime)
      if (this.skinInstance.currentAnimation != "HumanArtmature|Run") {
        this.skinInstance.setAnimation("HumanArmature|Run")
      }
    } else if (this.inputManager.keys.down.down) {
      transform.translateOnAxis(kBackward, this.moveSpeed * deltaTime)
      if (this.skinInstance.currentAnimation != "HumanArtmature|Walking") {
        this.skinInstance.setAnimation("HumanArmature|Walking")
      }
    } else {
      if (this.skinInstance.currentAnimation != "HumanArtmature|Idle") {
        this.skinInstance.setAnimation("HumanArmature|Idle")
      }
    }
  }
}