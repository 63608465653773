export default {
  time: 0,
  deltaTime: 0,
  sizes: {
    width: window.innerWidth,
    height: window.innerHeight
  },
  player: null,
  playerRadius: 2,
  history: []
} 