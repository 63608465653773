import dat from 'dat.gui'

export default class Debug {
  constructor() {
    this.gui = new dat.GUI()
  }

  add(element, param, min, max, step) {
    this.gui.add(element, param).min(min).max(max).step(step)
  }
}