import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
const progressbarElem = document.querySelector('.progressbar')

export default class Loader {
  constructor() {
    this.loadingManager = new THREE.LoadingManager()
    this.models = {
      pig: { url: '/game/models/Pig.fbx' },
      cow: { url: '/game/models/Cow.fbx' },
      llama: { url: '/game/models/Llama.fbx' },
      pug: { url: '/game/models/Pug.fbx' },
      sheep: { url: '/game/models/Sheep.fbx' },
      zebra: { url: '/game/models/Zebra.fbx' },
      horse: { url: '/game/models/Horse.fbx' },
      knight: { url: '/game/models/knight/KnightCharacter.fbx' }
    }
  }

  load() {
    // Progress Bar
    this.loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      progressbarElem.style.width = `${itemsLoaded / itemsTotal * 100 | 0}%`
    }

    const fbxLoader = new FBXLoader(this.loadingManager)
    for (const model of Object.values(this.models)) {
      fbxLoader.load(model.url, (fbx) => {
        model.fbx = fbx
      })
    }
  }

  prepModelsAndAnimations() {
    Object.values(this.models).forEach(model => {
      // console.log('----->: ', model.url)
      const animsByName = {};
      model.fbx.animations.forEach((clip) => {
        animsByName[clip.name] = clip
        // console.log('   ', clip.name)
      })
      model.animations = animsByName
    })
  }
}