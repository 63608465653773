import '../css/style.css'
import World from './components/World'
import InputManager from './components/InputManager'
import globals from './components/Globals'
import Player from './components/Player'
import Loader from './components/Loader'
import UI from './components/UI'
import Enemy from './components/Enemy'

const canvas = document.querySelector('canvas.game-webgl')
if (canvas) {
  boot()
}

function boot() {
  // Setup world
  const world = new World()
  world.init()

  // Load Assets
  const loader = new Loader()
  loader.load()
  loader.loadingManager.onLoad = init

  // UI
  const ui = new UI()

  // Player
  let player = null

  // Init Game
  function init() {
    const loadingElem = document.querySelector('.loading')
    loadingElem.classList.add('hide')
    setTimeout(() => {
      loadingElem.style.display = 'none'
    }, 350)

    loader.prepModelsAndAnimations()

    // Player
    player = gameObjectManager.createGameObject(world.scene, 'player')
    player.addComponent(Player, loader.models.knight)
    globals.player = player

    const enemy1 = gameObjectManager.createGameObject(world.scene, 'enemy1')
    enemy1.addComponent(Enemy, loader.models.horse)
    enemy1.transform.position.x = 5

    // UI
    ui.setHealth(player.components[1].currentHealth)
    console.log(player.components[1].currentHealth)
  }

  class GameObject {
    constructor(parent, name) {
      this.name = name
      this.components = []
      this.transform = new THREE.Object3D()
      parent.add(this.transform)
    }

    addComponent(componentType, ...args) {
      const component = new componentType(this, ...args)
      this.components.push(component)
      return component
    }

    removeComponent(component) {
      removeArrayElement(this.components, component)
    }

    getComponent(ComponentType) {
      return this.components.find(c => c instanceof ComponentType)
    }

    removeArrayElement(array, element) {
      const index = array.indexOf(element)
      if (index >= 0) {
        array.splice(index, 1)
      }
    }

    update() {
      for (const component of this.components) {
        component.update()
      }
    }
  }

  class SafeArray {
    constructor() {
      this.array = []
      this.addQueue = []
      this.removeQueue = new Set()
    }

    get isEmpty() {
      return this.addQueue.length + this.array.length > 0
    }

    add(element) {
      this.addQueue.push(element)
    }

    remove(element) {
      this.removeQueue.add(element)
    }

    forEach(fn) {
      this._addQueued()
      this._removeQueued
      for (const element of this.array) {
        if (this.removeQueue.has(element)) {
          continue
        }
        fn(element)
      }
      this._removeQueued()
    }

    _addQueued() {
      if (this.addQueue.length) {
        this.array.splice(this.array.length, 0, ...this.addQueue)
        this.addQueue = []
      }
    }

    _removeQueued() {
      if (this.removeQueue.size) {
        this.array = this.array.filter(element => !this.removeQueue.has(element))
        this.removeQueue.clear()
      }
    }
  }

  class GameObjectManager {
    constructor() {
      this.gameObjects = new SafeArray()
    }

    createGameObject(parent, name) {
      const gameObject = new GameObject(parent, name)
      this.gameObjects.add(gameObject)
      return gameObject
    }

    removeGameObject(gameObject) {
      this.gameObjects.remove(gameObject)
    }

    update() {
      this.gameObjects.forEach(gameObject => gameObject.update())
    }
  }
  const gameObjectManager = new GameObjectManager()
  const inputManager = new InputManager()

  // Render
  let then = 0
  function tick(now) {
    globals.time = now * 0.001
    globals.deltaTime = Math.min(globals.time - then, 1 / 20)
    then = globals.time

    gameObjectManager.update()
    inputManager.update()

    world.update()

    // Player updates
    if (player) {
      world.camera.camera.position.x = player.transform.position.x + 4
      world.camera.camera.position.y = player.transform.position.y + 4
      world.camera.camera.position.z = player.transform.position.z + 4
      world.camera.camera.lookAt(player.transform.position)
    }

    requestAnimationFrame(tick)
  }
  tick()
}